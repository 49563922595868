<template>
  <v-card class="elevation-4">
    <v-card-title class="justify-center py-4 card-modal-title">
      <div class="pl-4">
        <h4 class="title-card">
          <v-icon> fa-solid fa-file-excel</v-icon>
          <span>{{ $t("views.reports.status_offers.table.download_excel") }}</span>
        </h4>
      </div>
      <v-spacer />
      <v-btn depressed text color="primary" @click.stop="$emit('closeForm')" dense class="mr-2">
        <v-icon small class="mr-1">fa-regular fa-rectangle-xmark</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pa-0">
      <v-form v-model="valida" dense>
        <v-container align="center" justify="center">
          <v-row>
            <v-col cols="12" class="pt-2 pb-0 px-6">
              <label>{{ $t("views.reports.status_offers.table.download_excel_text") }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-2 pb-4 px-6">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                min-width="auto"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    :label="$t('views.reports.status_offers.table.download_excel_range')"
                    append-icon="fa-regular fa-calendar-days"
                    readonly
                    dense
                    outlined
                    hide-details
                    required
                    :rules="[
                      required($t('views.reports.status_offers.table.download_excel_range')),
                    ]"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>

                <v-date-picker v-model="dateDownload" :locale="$i18n.locale" range />
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions class="border-top my-0">
      <v-spacer />
      <v-btn
        color="light-green darken-3"
        :disabled="!valida"
        depressed
        class="white--text"
        @click="onGenerateExcel"
      >
        <v-icon small class="fa-solid fa-file-excel mr-1" />
        {{ $t("views.reports.availability.table.download_excel") }}
      </v-btn>
      <v-btn depressed @click.stop="$emit('closeForm')">
        <v-icon small size="15" class="mr-1"> fa-solid fa-xmark</v-icon>
        {{ $t("components.app.datatable.exit") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import validate from "@/plugins/validate";
import { GenerateExcel } from "./GenerateExcel";
import { ValidateDate } from "./ValidateExcel";

export default {
  data() {
    return {
      ...validate,
      valida: false,
      dateDownload: [],
      menu: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      search: "search/search",
    }),
    dateRangeText() {
      return this.dateDownload.join(" A ");
    },
  },
  metaInfo() {
    return {
      title: this.$t("components.layout.sidebar.status_of_offers"),
    };
  },
  watch: {
    dateDownload(newVal) {
      if (newVal[1] !== undefined) {
        let date1 = this.moment(newVal[0]),
          date2 = this.moment(newVal[1]);
        let diff = Math.abs(date2.diff(date1, "days"));
        if (diff > 30) {
          this.showSnack({
            text: this.$t("views.reports.status_offers.download_excel_range_title"),
            title: this.$t("views.reports.status_offers.download_excel_range_message"),
            name: "warning",
          });
        }
      }
    },
    token(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.token) {
          window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
        }
      }
    },
  },
  methods: {
    ...mapActions("loader", ["showLoader"]),
    ...mapActions("snackbar", ["showSnack"]),

    async onGenerateExcel() {
      this.showLoader({ show: true });
      const newDateFormat = ValidateDate(this.dateDownload);
      try {
        await GenerateExcel(newDateFormat);
      } catch (e) {}
      this.showLoader({ show: false });
    },
  },
};
</script>
<style scoped>
@import url("./ModalExcelGeneratorStyle.scss");
</style>
