import moment from "@/plugins/moment";
export const ValidateDate = (dateParam) => {
  const dateValue = [];
  if (dateParam) {
    dateParam.forEach((element, index) => {
      dateValue[index] = moment(element, "YYYY-MM-DD").utc().format("YYYY-MM-DDTHH:mm:ss.000");
    });
    if (!dateValue[1]) dateValue[1] = dateValue[0];
    return dateValue;
  }
};
