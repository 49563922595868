import axios from "@/plugins/axios";
import i18n from "@/plugins/i18n";
import moment from "@/plugins/moment";

export const GenerateExcel = async (dateParam) => {
  if (dateParam) {
    const [dateStart, dateEnd] = dateParam;

    const params = new URLSearchParams();
    params.append("startDate", dateStart.toString());
    params.append("endDate", dateEnd.toString());

    await axios({
      url: `/Offers/ExportOffersReports`,
      params,
      method: "GET",
      responseType: "arraybuffer",
      headers: {
        "Content-Type": `multipart/form-data;`,
      },
    })
      .then((response) => {
        const nameFile = `${i18n.t("components.layout.sidebar.status_of_offers")}${moment()
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.000")}.xlsx`;
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob),
          link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nameFile);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error(error);
        return new Error("Generate Excel:", { cause: error });
      });
  }
};
